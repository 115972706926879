/* import __COLOCATED_TEMPLATE__ from './action-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: {
      inserter: any;
      resolver: any;
    };
  };
}

const ActionInserter = templateOnlyComponent<Signature>();
export default ActionInserter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::ActionInserter': typeof ActionInserter;
    'workflows/graph-editor/node-items/steps/fin-custom/action-inserter': typeof ActionInserter;
  }
}
