/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Action from 'embercom/models/workflow-connector/action';
import type Store from '@ember-data/store';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  workflowAction: Action;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class SideDrawer extends Component<Signature> {
  @service router: any;
  @service declare store: Store;
  @tracked selectedTab: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.selectedTab = this.router?.currentRoute?.queryParams?.selectedTab || 'request_section';
    if (!this.args.workflowAction.contextualIdentifierConfig) {
      this.createEmptyContextualIdentifierConfig();
    }
  }

  createEmptyContextualIdentifierConfig() {
    this.args.workflowAction.contextualIdentifierConfig = this.store.createFragment(
      'workflow-connector/customer-data-verification/contextual-identifier-config',
      {
        name: '',
        descriptors: [],
        apiRequestConfig: this.store.createFragment(
          'workflow-connector/customer-data-verification/api-request-config',
        ),
      },
    );
  }

  @action
  closeSideSheet() {
    // TODO: Destroy the contextualIdentifierConfig model when we close the side drawer
    this.args.onClose();
  }

  @action
  onSelectionChange(sectionId: string) {
    this.selectedTab = sectionId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::SideDrawer': typeof SideDrawer;
    'workflow-connector/builder/body/customer-data-verification/side-drawer': typeof SideDrawer;
  }
}
