/* import __COLOCATED_TEMPLATE__ from './data-access.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';
import type Action from 'embercom/models/workflow-connector/action';

interface Args {
  workflowAction: Action;
}

interface Signature {
  Args: Args;
}

const DataAccess = templateOnlyComponent<Signature>();

export default DataAccess;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::DataAccess': typeof DataAccess;
    'workflow-connector/builder/body/sections/data-access': typeof DataAccess;
  }
}
