/* import __COLOCATED_TEMPLATE__ from './verify-identity.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export interface Args {
  idvEnabled: boolean;
  otpAuthEnabled: boolean;
  onUpdateOtpAuthEnabled: () => void;
  workflowAction: any;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class VerifyIdentity extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @tracked isCustomerDataVerificationEnabled = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  @action
  handleOTPSwitchToggle() {
    this.args.onUpdateOtpAuthEnabled();
  }

  get isMessengerSecured() {
    return this.args.idvEnabled;
  }

  get idvSetupUrl() {
    return 'apps.app.settings.channels.messenger.install';
  }

  get app() {
    return this.appService.app;
  }

  @action
  closeSideSheet() {
    this.isCustomerDataVerificationEnabled = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request::VerifyIdentity': typeof VerifyIdentity;
    'workflow-connector/builder/body/sections/request/verify-identity': typeof VerifyIdentity;
  }
}
