/* import __COLOCATED_TEMPLATE__ from './add-output.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { Type as DescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { TrackedArray } from 'tracked-built-ins';

interface Args {
  outputParams: OutputParam[];
  editorState: EditorState;
  readOnly: boolean;
  onAddOutputParam: (outputParam: OutputParam) => void;
  onUpdateOutputParam: (outputParam: OutputParam) => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
}

interface Signature {
  Args: Args;
}

class ListOption {
  id?: string;
  @tracked value: string;

  constructor(id: string | undefined, value = '') {
    this.id = id;
    this.value = value;
  }
}

class BackingObject {
  id?: string;
  @tracked name?: string;
  @tracked description?: string;
  @tracked descriptorType: DescriptorType = DescriptorType.string;
  @tracked listOptions: TrackedArray<ListOption> = new TrackedArray<ListOption>();

  constructor(outputParam?: OutputParam) {
    if (outputParam) {
      this.id = outputParam.id;
      this.name = outputParam.name;
      this.description = outputParam.description;
      this.descriptorType = outputParam.descriptorType;

      for (let option of outputParam.listOptions) {
        this.listOptions.push(new ListOption(option.id, option.value));
      }
    }
  }

  get hasListOptions() {
    return this.listOptions.length > 0;
  }

  get hasBlankListOptions() {
    return this.listOptions.some((option) => !option.value || option.value === '');
  }

  get hasDuplicateListOptions() {
    return new Set(this.listOptions.map((option) => option.value)).size < this.listOptions.length;
  }

  get isValid() {
    return (
      this.name &&
      this.name !== '' &&
      this.description &&
      this.description !== '' &&
      !(
        this.descriptorType === DescriptorType.workflow_attribute_descriptor_list &&
        (this.hasBlankListOptions || this.hasDuplicateListOptions)
      )
    );
  }

  addListOption(text: string) {
    this.listOptions.push(new ListOption(undefined, text));
  }

  removeListOption(index: number) {
    this.listOptions.splice(index, 1);
  }

  toOutputParam() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      descriptorType: this.descriptorType,
      listOptions: this.listOptions
        .map((option) => {
          if (option.value !== '') {
            return {
              id: option.id,
              value: option.value,
            };
          }

          return null;
        })
        .filter(Boolean),
    } as OutputParam;
  }
}

export default class AddOutput extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked modalIsVisible = false;
  @tracked backingObject?: BackingObject;

  get outputTypeOptions() {
    return [
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.string',
        ),
        icon: 'field',
        value: DescriptorType.string,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.integer',
        ),
        icon: 'numbers',
        value: DescriptorType.integer,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.boolean',
        ),
        icon: 'boolean',
        value: DescriptorType.boolean,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.list',
        ),
        icon: 'list',
        value: DescriptorType.workflow_attribute_descriptor_list,
      },
    ];
  }

  @action
  formatTypeLabel(descriptorType: DescriptorType) {
    return this.outputTypeOptions.find((option) => option.value === descriptorType)?.text;
  }

  @action
  showModal(outputParam?: OutputParam) {
    this.modalIsVisible = true;
    this.backingObject = new BackingObject(outputParam);
  }

  @action
  hideModal() {
    this.modalIsVisible = false;
    this.backingObject = undefined;
  }

  @action
  addOrUpdateOutputParam() {
    let outputParam = this.backingObject!.toOutputParam();

    if (this.backingObject!.id) {
      this.args.onUpdateOutputParam(outputParam);
    } else {
      this.args.onAddOutputParam(outputParam);
    }

    this.hideModal();
  }

  @action
  updateDescriptorType(descriptorType: DescriptorType) {
    this.backingObject!.descriptorType = descriptorType;
    if (descriptorType === DescriptorType.workflow_attribute_descriptor_list) {
      if (!this.backingObject!.hasListOptions) {
        this.backingObject!.addListOption('');
        this.backingObject!.addListOption('');
      }
    }
  }

  @action
  addListOption(text: string) {
    this.backingObject!.addListOption(text);
  }

  @action
  removeListOption(index: number) {
    this.backingObject!.removeListOption(index);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AddOutput': typeof AddOutput;
    'workflows/graph-editor/node-items/steps/fin-custom/add-output': typeof AddOutput;
  }
}
