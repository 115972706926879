/* import __COLOCATED_TEMPLATE__ from './request.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { HTTP_METHODS } from 'embercom/lib/custom-authentication/request-helper';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import type Token from 'embercom/models/custom-authentication/token';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type CustomerDataVerificationDescriptors from 'embercom/models/workflow-connector/customer-data-verification/descriptors';
import type ContextualIdentifierConfig from 'embercom/models/workflow-connector/customer-data-verification/contextual-identifier-config';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

interface Args {
  contextualIdentifierConfig: ContextualIdentifierConfig;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Request extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: any;

  @tracked customAuthenticationTokens: Token[] = [];
  @tracked isBodyAvailable = ['post', 'put', 'patch'].includes(this.apiRequestConfig.httpMethod);
  @tracked bodyBlocks;
  @tracked urlBlocks;
  @tracked selectedTokenId = this.apiRequestConfig.tokenId || undefined;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadCustomAuthenticationTokens).perform();
    this.bodyBlocks = this.workflowActionBodyToBlocks();
    this.urlBlocks = this.workflowActionURLToBlocks();
    if (this.apiRequestConfig.httpMethod === undefined) {
      this.apiRequestConfig.httpMethod = 'get';
    }
  }

  get apiRequestConfig() {
    return this.args.contextualIdentifierConfig.apiRequestConfig;
  }

  get supportedHttpMethods() {
    return HTTP_METHODS;
  }

  get selectedToken() {
    return this.customAuthenticationTokens?.find((token) => token.id === this.selectedTokenId);
  }

  get tokenList() {
    return [{ items: this.groupListItems }];
  }

  get groupListItems() {
    return this.customAuthenticationTokens.map((token) => {
      return {
        value: token.id,
        text: token.name,
      };
    });
  }

  get localAttributes() {
    let attributes = this.args.contextualIdentifierConfig.descriptors.map((descriptor) => {
      return {
        identifier: descriptor.name,
        name: descriptor.name,
        templatable_identifier: descriptor.name,
        noFallback: true,
      };
    });

    return [
      {
        heading: this.intl.t('workflow-connector.builder.body.request.fin-data.label'),
        attributes,
      },
    ];
  }

  workflowActionURLToBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.apiRequestConfig.url || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  workflowActionBodyToBlocks() {
    if (!this.apiRequestConfig.body) {
      return [];
    }

    let bodyLines = this.apiRequestConfig.body.split('\n');
    let bodyBlocks = bodyLines.map((line) => {
      return {
        type: 'paragraph',
        text: line,
        class: 'no-margin',
        modelKey: 'common/blocks/paragraph',
      };
    });
    return bodyBlocks;
  }

  @action
  updateDescriptor(
    index: string,
    name: string,
    description: string,
    type: string,
    required: boolean,
    defaultValue: string,
  ) {
    if (index) {
      let descriptor = this.args.contextualIdentifierConfig.descriptors.find(
        (x) => x.name === index,
      );
      if (descriptor) {
        descriptor.setProperties({
          name,
          description,
          type,
          required,
          defaultValue,
        });
      }
    } else {
      this.args.contextualIdentifierConfig.descriptors.pushObject({
        name,
        description,
        type,
        required,
        defaultValue,
      } as CustomerDataVerificationDescriptors);
    }
  }

  @action
  deleteDescriptor(index: string) {
    let descriptor = this.args.contextualIdentifierConfig.descriptors.find((x) => x.name === index);
    if (descriptor) {
      this.args.contextualIdentifierConfig.descriptors.removeObject(descriptor);
    }
  }

  @action
  setActionHttpMethod(httpMethod: string) {
    this.apiRequestConfig.httpMethod = httpMethod;
    this.isBodyAvailable = ['post', 'put', 'patch'].includes(httpMethod);
  }

  @action
  updateWorkflowToken(tokenId: string) {
    this.args.contextualIdentifierConfig.apiRequestConfig.tokenId = tokenId;
    this.selectedTokenId = tokenId;
  }

  @action
  clearSelectedToken() {
    this.args.contextualIdentifierConfig.apiRequestConfig.tokenId = null;
    this.selectedTokenId = undefined;
  }

  @action
  onBodyComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let blockTexts = blocks.map((block) => htmlUnescape(block.text));
    let processedBlocks = blockTexts.join('\n');
    this.apiRequestConfig.body = processedBlocks.toString();
  }

  @action
  onUrlComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let processedBlocks = sanitizeUrl(htmlUnescape(blocks[0].text));
    this.apiRequestConfig.url = processedBlocks.toString();
  }

  @task({ drop: true })
  *loadCustomAuthenticationTokens(): TaskGenerator<void> {
    this.customAuthenticationTokens = yield this.store.findAll('custom-authentication/token');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::Sections::Request': typeof Request;
    'workflow-connector/builder/body/customer-data-verification/sections/request': typeof Request;
  }
}
