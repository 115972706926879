/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ConversationRatings from 'embercom/models/operator/visual-builder/step/conversation-ratings';

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}
interface Arguments {
  step: ConversationRatings;
  isReadOnly: boolean;
}

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
export default class Settings extends Component<Signature> {
  @tracked toggle =
    this.args.step.blockUpdateAfter !== undefined && this.args.step.blockUpdateAfter !== null;

  @tracked ratingToggle =
    this.args.step.blockRatingAfter !== undefined && this.args.step.blockRatingAfter !== null;

  @tracked blockRatingAfterDays: number | string | undefined;
  @tracked blockRatingAfterHours: number | string | undefined;
  @tracked blockRatingAfterMinutes: number | string | undefined;

  @tracked blockUpdateAfterDays: number | string | undefined;
  @tracked blockUpdateAfterHours: number | string | undefined;
  @tracked blockUpdateAfterMinutes: number | string | undefined;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this._populateOptions();
  }

  _populateOptions() {
    this.blockRatingAfterDays = this.args.step.blockRatingAfterDays;
    this.blockRatingAfterHours = this.args.step.blockRatingAfterHours;
    this.blockRatingAfterMinutes = this.args.step.blockRatingAfterMinutes;

    this.blockUpdateAfterDays = this.args.step.blockUpdateAfterDays;
    this.blockUpdateAfterHours = this.args.step.blockUpdateAfterHours;
    this.blockUpdateAfterMinutes = this.args.step.blockUpdateAfterMinutes;
  }

  @action
  toggleBlockRating() {
    this.ratingToggle = !this.ratingToggle;
    this.args.step.blockRatingAfter = MINUTE;

    if (!this.ratingToggle) {
      this.args.step.blockRatingAfter = undefined;
    }
  }

  @action
  toggleRatingChange() {
    this.toggle = !this.toggle;
    this.args.step.blockUpdateAfter = MINUTE;

    if (!this.toggle) {
      this.args.step.blockUpdateAfter = undefined;
    }
  }

  @action
  toggleWaitForRating() {
    this.args.step.waitForRating = !this.args.step.waitForRating;

    if (!this.args.step.waitForRating) {
      this.args.step.enableComposerWhileWaitingForRating = false;
    }
  }

  @action
  setBlockUpdateAfter() {
    this.args.step.blockUpdateAfter = this.blockUpdateAfter;
  }

  @action
  setBlockRatingAfter() {
    this.args.step.blockRatingAfter = this.blockRatingAfter;
  }

  get blockRatingAfter() {
    return (
      this._parseInt(this.blockRatingAfterDays) * DAY +
      this._parseInt(this.blockRatingAfterHours) * HOUR +
      this._parseInt(this.blockRatingAfterMinutes) * MINUTE
    );
  }

  get blockUpdateAfter() {
    return (
      this._parseInt(this.blockUpdateAfterDays) * DAY +
      this._parseInt(this.blockUpdateAfterHours) * HOUR +
      this._parseInt(this.blockUpdateAfterMinutes) * MINUTE
    );
  }

  _parseInt(value: string | number | undefined): number {
    if (value === undefined) {
      return 0;
    } else if (value === '') {
      return 0;
    } else if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ConversationRatings::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/conversation-ratings/settings': typeof Settings;
  }
}
