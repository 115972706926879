/* import __COLOCATED_TEMPLATE__ from './forward-call.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type ForwardCall from 'embercom/models/operator/visual-builder/step/forward-call';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: ForwardCall;
  editorState: EditorState;
  readOnly: boolean;
  isTemplatePreview: boolean;
}

export default class ForwardCallNodeItemStep extends Component<Arguments> {
  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ForwardCall': typeof ForwardCallNodeItemStep;
    'workflows/graph-editor/node-items/steps/forward-call': typeof ForwardCallNodeItemStep;
  }
}
