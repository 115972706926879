/* import __COLOCATED_TEMPLATE__ from './index-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';

export default class IndexComponent extends Component {
  @service appService;
  @service router;
  @service notificationsService;
  @service modalService;
  @service store;
  @service intercomConfirmService;
  @service intercomEventService;
  @service intl;
  @tracked selectedWorkflows = [];
  @tracked operatorSettings;

  constructor() {
    super(...arguments);
    this.loadOperatorBotInboxSettings.perform();
  }

  get app() {
    return this.appService.app;
  }

  get canViewRulesAfterMigratingToWorkflows() {
    return (
      this.app.canUseFeature('disable-inbox-rules') &&
      this.appService.app.canUseFeature('deep-link-inbox-rules-to-workflows')
    );
  }

  get isInbox2Route() {
    return this.router.currentRouteName.startsWith('apps.app.settings.inbox-rules');
  }

  get workflows() {
    if (!this.canViewRulesAfterMigratingToWorkflows) {
      return this.args.workflows;
    }
    return this.args.workflows.filter((workflow) => workflow.migratedToRulesetIds.length > 0);
  }

  get selectedWorkflowsCount() {
    return this.selectedWorkflows?.length || 0;
  }

  get canUseCleanupWizard() {
    // can use cleanup wizard if bot inbox is disabled and hasn't been enabled in the last 6 months
    let sixMonthsAgo = moment().subtract(6, 'months').toDate();
    return (
      !this.operatorSettings?.assignConversationsToBots &&
      (!this.operatorSettings?.assignConversationsToBotsLastEnabledAt ||
        this.operatorSettings?.assignConversationsToBotsLastEnabledAt < sixMonthsAgo)
    );
  }

  @action
  handleSelect(workflowId) {
    let updatedSelectedWorkflows;
    if (this.selectedWorkflows.includes(workflowId)) {
      updatedSelectedWorkflows = this.selectedWorkflows.without(workflowId);
    } else {
      updatedSelectedWorkflows = [...this.selectedWorkflows, workflowId];
    }
    this.selectedWorkflows = updatedSelectedWorkflows;
  }

  @action
  newWorkflow() {
    this.router.transitionTo('apps.app.settings.helpdesk.rules.new');
  }

  @action
  showRebuildRulesArticle() {
    window.Intercom('showArticle', 7857898); // https://www.intercom.com/help/en/articles/7857898-build-rules-based-automations-in-workflows
  }

  @action
  onDraggingEnd(workflows, movedWorkflow) {
    let newIndex = workflows.indexOf(movedWorkflow);
    if (movedWorkflow.order !== newIndex) {
      workflows.forEach((workflow, index) => {
        workflow.set('order', index);
      });
      movedWorkflow
        .reorder(newIndex)
        .then(() => this.notificationsService.notifyConfirmation("Rule's order updated"))
        .catch(() => this.notificationsService.notifyError('Unable to update rule order'));
    }
  }

  get staleWorkflows() {
    let sixMonthsAgo = moment().subtract(6, 'months').toDate();
    let workflows = this.args.workflows.filter((workflow) => {
      return (
        workflow.createdAt <= sixMonthsAgo &&
        (workflow.lastAppliedAt === null || workflow.lastAppliedAt <= sixMonthsAgo)
      );
    });
    return workflows;
  }

  @action openCleanUpModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'clean_up_button',
      place: 'inbox_rules',
    });
    this.modalService.openModal('settings/modals/clean-up-inbox-rules', {
      workflows: this.staleWorkflows,
    });
  }

  @task({ drop: true })
  *loadOperatorBotInboxSettings() {
    this.operatorSettings = yield this.store.findRecord('operator-bot-inbox-settings', this.app.id);
  }

  @task({ drop: true })
  *bulkDeleteWorkflows() {
    let confirmOptions = {
      title: this.intl.t('settings.inbox-rules.list.delete-popup.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.bulk-delete'),
      cancelButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.cancel'),
      body: this.intl.t('settings.inbox-rules.list.delete-popup.bulk-delete-body'),
    };
    let isConfirmed = yield this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      this.selectedWorkflows = [];
      return;
    }
    try {
      yield ajax({
        url: `/ember/workflows/bulk_delete`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
          workflow_ids: this.selectedWorkflows.map(Number),
        }),
      });
      yield this.selectedWorkflows.forEach((workflow, index) => {
        let recordToDelete = this.store.peekRecord('workflow', workflow.toString());
        this.store.unloadRecord(recordToDelete);
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.inbox-rules.list.delete-popup.bulk-success', {
          number: this.selectedWorkflowsCount,
        }),
      );
      this.selectedWorkflows = [];
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.inbox-rules.list.delete-popup.bulk-error'),
      );
    }
  }

  @action
  gotoWorkflows() {
    this.router.transitionTo('apps.app.automation.workflows-overview');
  }
}
