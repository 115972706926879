/* import __COLOCATED_TEMPLATE__ from './layout-module.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  headerTitle?: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    actions: [];
    content: [];
  };
  Element: HTMLElement;
}

const LayoutModule = templateOnlyComponent<Signature>();
export default LayoutModule;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::LayoutModule': typeof LayoutModule;
    'workflows/preview/layout-module': typeof LayoutModule;
  }
}
