/* import __COLOCATED_TEMPLATE__ from './popover-menu.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { type StepGroupType } from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { type PopoverArgs } from '@intercom/pulse/template-registry';
import type InserterMenuConfiguration from 'embercom/objects/visual-builder/configuration/inserter-menu';
import type { StepParameters } from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

type Args = Pick<PopoverArgs, 'placement' | 'showArrow' | 'onShow' | 'onHide'> & {
  insertionOptions: StepGroupType[];
  shouldShowStepPaywalls?: boolean;
  showSearchBar?: boolean;
  fixedHeight?: boolean;
  class?: string;
  onSelectItem?: (
    stepParams: StepParameters,
    groupId?: string,
  ) => ReturnType<InserterMenuConfiguration['onSelectItem']>;
};

export default class PopoverMenu extends Component<Signature> {
  @tracked searchTerm = '';
  @tracked viewMore = false;

  @action
  updateSearchTerm(event: InputEvent & { target: HTMLInputElement }) {
    this.searchTerm = event.target.value;
    this.viewMore = true;
  }

  get items() {
    let _items = this.args.insertionOptions.map((group) => {
      return {
        ...group,
        items: group.items.filter(
          (i) =>
            i.text.toLowerCase().includes(this.searchTerm.toLowerCase()) && group.items.length > 0,
        ),
      };
    });
    if (this.searchTerm) {
      return _items.filter((group) => group.groupId !== 'suggested-next-steps');
    }
    return _items;
  }

  get firstItem() {
    return this.items.slice(0, 1).firstObject;
  }

  get restOfItems() {
    return this.items.slice(1);
  }

  get itemsPresent() {
    return this.items.some((group) => group.items.length > 0);
  }

  get popoverMenuHeight() {
    return !this.viewMore ? 'h-auto' : 'h-130';
  }

  @action
  toggleViewMore() {
    this.viewMore = !this.viewMore;
  }

  @action
  clearSearchTerm() {
    this.searchTerm = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PopoverMenu::PopoverMenu': typeof PopoverMenu;
  }
}
