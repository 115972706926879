/* import __COLOCATED_TEMPLATE__ from './users-companies-with-icon-pattern.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { inject as service } from '@ember/service';

export default class UsersCompaniesWithIconPattern extends Component {
  @service appService;
  @service session;

  @action
  onCompanyNameClick(event) {
    event.stopPropagation();
  }

  get appId() {
    try {
      return this.session.workspace.id;
    } catch (error) {
      // session should always be loaded but we can use this as backup
      return this.appService?.app?.id;
    }
  }

  get othersUrl() {
    return B64FilterParamEncoder.filterUrlFromPredicates(
      this.args.user.mostRecentCompany,
      this.asPredicates,
    );
  }

  get asPredicates() {
    return {
      predicates: [
        {
          type: 'anonymous',
          attribute: 'anonymous',
          comparison: false,
          value: undefined,
        },
        {
          type: 'or',
          predicates: this.companyPredicate,
        },
      ],
    };
  }

  get companyPredicate() {
    return this.args.user.companies.map(function (company) {
      return {
        attribute: 'company.remote_company_id',
        type: 'string',
        comparison: 'eq',
        value: company.remote_company_id,
      };
    });
  }
}
