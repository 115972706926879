/* import __COLOCATED_TEMPLATE__ from './object-mapping.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';
import type Action from 'embercom/models/workflow-connector/action';

interface Args {
  workflowAction: Action;
  nextSection: (sectionId: string) => void;
}

interface Signature {
  Args: Args;
}

const ObjectMapping = templateOnlyComponent<Signature>();

export default ObjectMapping;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::ObjectMapping': typeof ObjectMapping;
    'workflow-connector/builder/body/sections/object-mapping': typeof ObjectMapping;
  }
}
