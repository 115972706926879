/* import __COLOCATED_TEMPLATE__ from './fin-preview-url-builder.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  languageOverride?: string;
  shareableFinToken?: string;
  customActionId?: string;
  demoType?: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [
      {
        messengerUrl: string;
      },
    ];
  };
  Element: HTMLElement;
}

export default class FinPreviewUrlBuilder extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare session: Session;

  get appId() {
    return this.appService.app.id;
  }

  get messengerUrl() {
    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/fin_demo_preview?preview_only=true&intercom_disable_cookies=true`;

    if (
      !this.appService.app.canUseFeature('ai-agent-multilingual-remove-preview-languages-select')
    ) {
      iframeUrl += `&language_override=${this.args.languageOverride ?? 'en'}`;
    }
    if (this.args.shareableFinToken) {
      iframeUrl += `&source=shareablefinpreview&token=${this.args.shareableFinToken}`;
    }
    if (this.args.customActionId) {
      iframeUrl += `&custom_action_id=${this.args.customActionId}`;
    }
    if (this.args.demoType) {
      iframeUrl += `&demo_type=${this.args.demoType}`;
    }

    return iframeUrl;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::FinPreviewUrlBuilder': typeof FinPreviewUrlBuilder;
    'workflows/preview/fin-preview-url-builder': typeof FinPreviewUrlBuilder;
  }
}
