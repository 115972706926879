/* import __COLOCATED_TEMPLATE__ from './handoff-to-salesforce-case.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type HandoffToSalesforceCaseType from 'embercom/models/operator/visual-builder/step/handoff-to-salesforce-case';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type IntlService from 'ember-intl/services/intl';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import { isPresent } from '@ember/utils';
import { type SalesforceSetupData } from 'embercom/objects/standalone/salesforce';

export interface HandoffToSalesforceCaseArgs {
  step: HandoffToSalesforceCaseType;
  editorState: EditorState;
  readOnly: boolean;
  isTemplatePreview?: boolean;
}

export interface HandoffOption {
  text: string;
  value: string | number;
}

export interface HandoffOptionGroup {
  heading: string;
  items: HandoffOption[];
}

export default class HandoffToSalesforceCase extends Component<HandoffToSalesforceCaseArgs> {
  @service declare intl: IntlService;
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }

  get salesforceSetupData(): SalesforceSetupData {
    return this.finStandaloneService.salesforceSetupData;
  }

  get isLoading(): boolean {
    return (
      this.finStandaloneService.isLoadingSalesforceSetupData ||
      this.finStandaloneService.isLoadingSalesforceConfig
    );
  }

  get handoverOptions(): HandoffOptionGroup[] {
    let admins = this.salesforceSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    let queues = this.salesforceSetupData.queues?.map((queue) => ({
      text: queue.name,
      value: queue.id,
    }));

    let options = [];

    if (isPresent(queues)) {
      options.push({
        heading: this.intl.t(
          'operator.workflows.visual-builder.handoff-to-salesforce-case.settings.queues-title',
        ),
        items: queues,
      });
    }

    if (isPresent(admins)) {
      options.push({
        heading: this.intl.t(
          'operator.workflows.visual-builder.handoff-to-salesforce-case.settings.users-title',
        ),
        items: admins,
      });
    }

    return options;
  }

  get selectedHandoverOption(): HandoffOption | undefined {
    let selectedHandoverOption = this.handoverOptions
      .flatMap((group) => group.items)
      .find((option) => option.value === this.args.step.handoffToSalesforceOwnerId);
    return selectedHandoverOption;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToSalesforceCase': typeof HandoffToSalesforceCase;
    'workflows/graph-editor/node-items/steps/handoff-to-salesforce-case': typeof HandoffToSalesforceCase;
  }
}
