/* import __COLOCATED_TEMPLATE__ from './preview-module.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  onClose: () => void;
  messengerUrl: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class PreviewModule extends Component<Signature> {
  @tracked selectedTab = 'customer';
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::PreviewModule': typeof PreviewModule;
    'workflows/preview/preview-module': typeof PreviewModule;
  }
}
