/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type Block } from 'embercom/models/common/blocks/block';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import { TrackedArray } from 'tracked-built-ins';

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  editorState: EditorState;
  isReadOnly: boolean;
  onAddOutputParam: (outputParam: OutputParam) => void;
  onUpdateOutputParam: (outputParam: OutputParam) => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
  outputParams: OutputParam[];
}
export default class Settings extends Component<Signature> {
  blocksDoc: BlocksDocument = new BlocksDocument([]);
  @tracked instructionsBlockDocs = new TrackedArray<BlocksDocument>([]);
  @service intl: any;
  @service appService: any;
  @service attributeService: any;
  @service store: any;
  @service contentEditorService: any;

  @tracked composerApi: any;
  @tracked openInserters: any;
  @tracked selectedTab = 'configure_section';
  @tracked allowMultiTurn = this.args.step.allowMultiTurn;
  @tracked allowContentSearch = this.args.step.allowContentSearch;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    // @ts-ignore - SyncHasMany is compatible with BlocksDocument but types don't match
    this.blocksDoc = new BlocksDocument(this.args.step.blocks);
    this.reinitializeInstructionBlocks();

    // Initialize allowMultiTurn if not already set
    if (this.args.step.allowMultiTurn === undefined) {
      this.args.step.set('allowMultiTurn', true);
      this.allowMultiTurn = true;
    }
  }

  get app() {
    return this.appService.app;
  }

  get templateOptionsList() {
    return [
      {
        inserter: this.insertTemplate,
        resolver: this.args.stepConfig.attributeInfoResolver,
        component: 'workflows/graph-editor/node-items/steps/fin-custom/action-inserter',
        componentShouldReplaceItem: true,
        isDisabled: this.args.stepConfig.attributeInfoResolver.customActions.length < 1,
      },
      {
        inserter: this.insertTemplate,
        resolver: this.args.stepConfig.attributeInfoResolver,
        component: 'workflows/graph-editor/node-items/steps/fin-custom/attribute-inserter',
        componentShouldReplaceItem: true,
      },
    ];
  }

  composerConfig(placeholder: string) {
    let config = new ComposerConfig({
      app: this.app,
      resolver: this.args.stepConfig.attributeInfoResolver,
      placeholder,
      allowMentions: false,
    });

    config.isInteractivityDisabled = this.args.isReadOnly;
    config.tools = config.tools.filter((tool) => tool.name !== 'template-inserter');
    return config;
  }

  get goalComposerConfig() {
    return this.composerConfig(
      this.intl.t('operator.workflows.visual-builder.fin-custom-settings.prompt-placeholder'),
    );
  }

  get instructionComposerConfig() {
    return this.composerConfig(
      this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.instruction-prompt-placeholder',
      ),
    );
  }

  get canShowContentSearchToggle() {
    return this.app.canUseFeature('answerbot-fin-agentic-content-search');
  }

  get shouldShowAgenticSettings() {
    return this.canShowContentSearchToggle;
  }

  reinitializeInstructionBlocks() {
    return (this.instructionsBlockDocs = this.args.step.instructions.length
      ? new TrackedArray<BlocksDocument>(
          this.args.step.instructions.map(
            (instructionBlocks) => new BlocksDocument(instructionBlocks),
          ),
        )
      : new TrackedArray<BlocksDocument>([new BlocksDocument([])]));
  }

  @action
  insertTemplate(attribute: any) {
    let prosemirrorState = this.composerApi?.composer.state.prosemirrorState;
    if (prosemirrorState) {
      let templateSpec = prosemirrorState.schema.nodes.template;
      let pos = prosemirrorState.selection.from;
      let tr = prosemirrorState.tr;
      let node = templateSpec.createAndFill({ attributePath: attribute, fallback: '' });
      tr.insert(pos, node);
      this.composerApi?.composer.commands.dispatch(tr);
      this.composerApi?.composer.commands.focus();
    }
  }

  @action
  onSelectionChange(sectionId: string) {
    this.selectedTab = sectionId;
  }

  @action
  addNewInstruction() {
    this.addInstruction(-1); // add empty instruction at first index
  }

  @action
  addInstruction(index: number) {
    this.reinitializeInstructionBlocks();
    this.instructionsBlockDocs.splice(index + 1, 0, new BlocksDocument([])); // Insert empty doc after current index

    this.instructionsBlockDocs.forEach((instructionsBlockDoc, index) => {
      let blockFragments: Array<Block> = instructionsBlockDoc.blocks.map((block) =>
        createFragmentFromBlock(this.store, block),
      );
      this.args.step.instructions[index] = blockFragments;
    });
    this.args.step.notifyPropertyChange('instructions');
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );

    this.unloadBlocks(this.args.step.blocks);
    this.args.step.set('blocks', blockFragments);
    this.args.step.notifyPropertyChange('blocks');
  }

  @action
  updateInstruction(index: number, blocksDoc: BlocksDocument) {
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );

    this.unloadBlocks(this.args.step.instructions[index]);
    this.args.step.instructions[index] = blockFragments;
    this.args.step.notifyPropertyChange('instructions');
  }

  @action
  deleteInstruction(index: number) {
    if (this.args.step.instructions.length > 1) {
      this.instructionsBlockDocs.splice(index, 1);
      this.unloadBlocks(this.args.step.instructions[index]);
      this.args.step.instructions = this.args.step.instructions.filter((_, i) => i !== index);
    }
  }

  unloadBlocks(blocks: any) {
    blocks?.forEach((fragment: Block) => {
      if (fragment && typeof fragment.unloadRecord === 'function') {
        fragment.unloadRecord();
      }
    });
  }

  @action
  toggleAllowMultiTurn() {
    this.allowMultiTurn = !this.allowMultiTurn;
    this.args.step.set('allowMultiTurn', this.allowMultiTurn);
  }

  @action
  toggleAllowContentSearch() {
    this.allowContentSearch = !this.allowContentSearch;
    this.args.step.set('allowContentSearch', this.allowContentSearch);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/fin-custom/settings': typeof Settings;
  }
}
