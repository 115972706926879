/* import __COLOCATED_TEMPLATE__ from './messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

interface Args {
  messengerUrl: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const IFRAME_CLASSES = 'w-full h-full';

export default class MessengerPreview extends Component<Signature> {
  @tracked isLoadingIframe = false;

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');

    iframe.addEventListener('load', () => {
      this.isLoadingIframe = false;
    });

    iframe.src = this.args.messengerUrl;
    this.isLoadingIframe = true;

    iframe.setAttribute('id', 'hosted-messenger-unified-preview');
    iframe.setAttribute('class', IFRAME_CLASSES);

    el.replaceChildren(iframe);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::MessengerPreview': typeof MessengerPreview;
    'workflows/preview/messenger-preview': typeof MessengerPreview;
  }
}
