/* import __COLOCATED_TEMPLATE__ from './preview-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-jquery */
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import isValidUrl from 'embercom/lib/url-validator';
import $ from 'jquery';
import { EntityType } from 'embercom/models/data/entity-types';

export default class PreviewSelector extends Component {
  @service store;
  @service intersectionService;
  @service intercomEventService;
  @service appService;
  @service intl;
  @service session;

  @tracked selectedDomain;
  @tracked loading = true;
  @tracked pingDomains = [];
  @tracked isManuallySelectingDomain = false;
  @tracked isSelectingRemotePreview = false;

  constructor(...args) {
    super(...args);
    this.fetchDomains.perform();
    // Keep this here for legacy reason, we need to ship the new handler below, update intersection and then delete this one
    this.intersectionService.registerEventHandler('getCustomBot', (data) => {
      return {
        id: this.args.previewableWorkflow.id,
        composerSuggestions: this.previewConfiguration.composerSuggestions,
        title: this.previewConfiguration.previewTitle,
      };
    });
    this.intersectionService.registerEventHandler('getWorkflowable', (data) => {
      return {
        workflowInstanceId: this.args.previewableWorkflow.latestWorkflowInstanceId,
        composerSuggestions: this.previewConfiguration.composerSuggestions,
        title: this.previewConfiguration.previewTitle,
      };
    });
    this._trackFinPreviewEvent();
  }

  get app() {
    return this.appService.app;
  }

  get previewConfiguration() {
    return this.args.previewableWorkflow.previewConfiguration;
  }

  get isValidSelectedDomain() {
    return isValidUrl(this.selectedDomain);
  }

  get modalTitle() {
    return this.args.title || this.intl.t('operator.custom-bot.preview.select-a-website');
  }

  get pingDomainOptions() {
    return this.pingDomains.map(function (domain) {
      return { text: domain, value: domain };
    });
  }

  get showAnswersDisabledWarning() {
    let previewableWorkflow = this.args.previewableWorkflow;
    return (
      previewableWorkflow.entityType === EntityType.ResolutionBotBehavior &&
      !previewableWorkflow.useAiAnswers &&
      !previewableWorkflow.useCustomAnswers
    );
  }

  focusInput() {
    schedule('afterRender', this, () => {
      $('.messenger_trigger__editor__element_selector__input').focus();
    });
  }

  _trackFinPreviewEvent() {
    if (!this.args.previewableWorkflow.useAiAnswers) {
      return;
    }
    this.intercomEventService.trackEvent('launched-fin-preview');
  }

  @task({ drop: true })
  *fetchDomains() {
    if (isEmpty(this.pingDomains)) {
      let pingDomains = yield this.store.query('ping-domain', { include_subdomains: true });
      this.pingDomains = pingDomains.mapBy('domain').uniq();
      if (ENV.environment === 'development') {
        this.pingDomains.pushObjects(['http://intercom.test', 'http://intercom-js.test']);
      }
      this.selectedDomain = this.pingDomains.firstObject;
    } else {
      this.selectedDomain = this.pingDomains.firstObject;
    }
    this.loading = false;
  }

  @action
  closeModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'operator',
      place: 'custom_bots',
      object: 'preview_modal',
    });

    this.args.closeModal();
  }

  @action
  selectDomain(domain) {
    this.selectedDomain = domain;
  }

  @action
  enableRemotePreviewSelection() {
    this.isSelectingRemotePreview = true;
  }

  @action
  resetLocalPreview() {
    let { intersectionWindow, targetDomain } = this.intersectionService;
    intersectionWindow.postMessage('restart_preview', targetDomain.origin);
  }

  @action
  enableManualDomainSelection() {
    this.isManuallySelectingDomain = true;
    this.focusInput();
  }

  @action
  openSite() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'preview_started',
      object: this.previewConfiguration.analyticsEventObjectLabel,
      models: [this.args.previewableWorkflow],
      from_website: true,
    });

    this.intersectionService.openWindow(
      this.selectedDomain,
      `${this.previewConfiguration.intersectionMode}-preview`,
    );
    this.args.closeModal();
  }
}
