/* import __COLOCATED_TEMPLATE__ from './custom-action-restricted-tab-content.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';

const REQUEST_SECTION = 'request_section';
const RESPONSE_SECTION = 'response_section';
const TESTING_SECTION = 'testing_section';
const DATA_ACCESS_SECTION = 'data_access_section';
const OBJECT_MAPPING_SECTION = 'object_mapping_section';
const GENERAL_SECTION = 'general_section';

type Tab =
  | typeof REQUEST_SECTION
  | typeof RESPONSE_SECTION
  | typeof TESTING_SECTION
  | typeof DATA_ACCESS_SECTION
  | typeof OBJECT_MAPPING_SECTION
  | typeof GENERAL_SECTION;

interface Args {
  workflowAction: $TSFixMe;
  selectedTab: Tab;
  tabName: Tab;
  label: string;
  onSelectionChange: (selectedTab: Tab) => void;
  tabDisabledTooltip: string;
}

interface Signature {
  Args: Args;
  Element: Element;
}

const CustomActionRestrictedTabContent = templateOnlyComponent<Signature>();
export default CustomActionRestrictedTabContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::CustomActionRestrictedTabContent': typeof CustomActionRestrictedTabContent;
    'workflow-connector/custom-action-restricted-tab-content': typeof CustomActionRestrictedTabContent;
  }
}
