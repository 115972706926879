/* import __COLOCATED_TEMPLATE__ from './attribute-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: {
      inserter: any;
      resolver: any;
    };
  };
}

const AttributeInserter = templateOnlyComponent<Signature>();
export default AttributeInserter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AttributeInserter': typeof AttributeInserter;
    'workflows/graph-editor/node-items/steps/fin-custom/attribute-inserter': typeof AttributeInserter;
  }
}
