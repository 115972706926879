/* import __COLOCATED_TEMPLATE__ from './learn-button.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';

export default class LearnButton extends Component {
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;

  @action trackDropdownClick(name: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: name,
      place: 'learn-dropdown',
      section: 'contacts',
    });
  }

  @action openTrackCustomerLink() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094815-tracking-filtering-grouping-your-customers',
      '_blank',
    );
  }

  @action openAppsIntegrationLink() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094744-apps-integrations',
      '_blank',
    );
  }

  @action navigateToAppStore() {
    this.router.transitionTo('apps.app.settings.app-settings.app-store');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UsersAndCompanies::SegmentTitle::LearnButton': typeof LearnButton;
    'users-and-companies/segment-title/learn-button': typeof LearnButton;
  }
}
