/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class SettingsComponent extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get workflowsBannerContent() {
    return this.intl
      .t(
        'operator.task-bots.leads.when-leads-start-a-conversation.route-existing-customers.workflows-discoverability-banner',
      )
      .split('%');
  }

  get continuedDescription() {
    return ` ${this.intl.t(
      'onboarding.home.convert-visitors-to-leads-or-customers.route-support-conversations-to-the-right-team.settings.continued-description',
    )}`;
  }

  workflowsContentWrapper(text, order) {
    return text[order];
  }
}
