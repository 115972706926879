/* import __COLOCATED_TEMPLATE__ from './standard-body.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type ContextualIdentifierConfig from 'embercom/models/workflow-connector/customer-data-verification/contextual-identifier-config';
interface Args {
  contextualIdentifierConfig: ContextualIdentifierConfig;
  section: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class StandardBody extends Component<Signature> {
  constructor(owner: unknown, args: any) {
    super(owner, args);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::StandardBody': typeof StandardBody;
    'workflow-connector/builder/body/customer-data-verification/standard-body': typeof StandardBody;
  }
}
